.contact {
	overflow: hidden;
	.map {
		width: 100%;
		height: 500px;
		overflow: hidden;
		img {
			height: 100%;
			width: 120%;
			object-fit: cover;
		}

		@include respond-below(sm) {
			height: 300px;
			width: 130%;
		}
	}

	.addresses {
		position: relative;
		.content {
			position: relative;
			top: 5rem;
			padding: 8rem 0;
			transition: 1s all;
			transition-delay: 200ms;
			&.animated {
				top: 0;
			}
			@include respond-below(lg) {
				padding: 5rem 0;
			}
			@include respond-below(md) {
				padding: 3rem 0;
			}
		}
		.address {
			z-index: 1031;
			padding: 2rem;
			margin-bottom: 2rem;
			p {
				bottom: 2rem;
				font-size: 1rem;
				line-height: 2;
			}
		}
	}
	.enquiries {
		position: relative;
		height: 400px;
		width: 100%;
		@include respond-below(sm) {
			height: 500px;
		}

		.video-container {
			position: absolute;
			left: 0;
			width: 60%;
			height: 300px;
			@include respond-below(sm) {
				width: 100%;
			}
			video {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.content {
			position: absolute;
			right: 0;
			top: 80px;
			width: 60%;
			padding: 3rem 8rem;
			background-color: white;
			@include respond-below(lg) {
				padding: 3rem 5rem;
				top: 40px;
			}
			@include respond-below(md) {
				padding: 2rem;
				top: 40px;
			}
			@include respond-below(sm) {
				width: 100%;
				padding: 2rem;
				top: 160px;
			}
			p {
				bottom: 2rem;
				font-size: 1rem;
				line-height: 2;
			}
		}
	}
}
